
.formContainerNonLoggedIn {
    border: solid 1px #C3C2C7;
    background-color: white;
    color: black;
    border-radius: 20px;
}

.notSelectedCircle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: solid 2px #C3C2C7;
    color: #C3C2C7;
    font-size: 14px;
}

.selectedCircle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: solid 2px #5D5FEF;
    color: #5D5FEF;
    font-size: 14px;
}

.filledCircle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    background-color: #5D5FEF;
    font-size: 14px;
}

.unApprovedCircle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    background-color: #FEAD63;
    font-size: 14px;
}

.approvedCircle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    background-color: #00B69B;
    font-size: 14px;
}

.notSelectedText {
 font-weight: 700;
 color: #302E3E;
 font-size: 14px;
}

.selectedText {
    font-weight: 700;
    color: #5D5FEF;
    font-size: 14px;
}

.filledText {
    font-weight: 700;
    color: #5D5FEF;
    font-size: 14px;
}

.unApprovedText {
    font-weight: 700;
    color: #FEAD63;
    background-color: #F7E6D9;
    font-size: 14px;
}

.approvedText {
    font-weight: 700;
    color: #00B69B;
    background-color: #CCF0EB;
    font-size: 14px;
}

.inprogress-status {
    border-radius: 4px;
    background-color: #CCF0EB;
    color: #00B69B;
    font-weight: 700;
    padding: 10px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

